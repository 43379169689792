const SPLIT_IO_TREATMENT = {
  CONTROL: 'control',
  OFF: 'off',
  ON: 'on',
} as const;

type SplitIoTreatment = typeof SPLIT_IO_TREATMENT[keyof typeof SPLIT_IO_TREATMENT];

const CHANNEL_NAME = 'juniqe';

const SPLIT_TRAFFIC_TYPE = { USER: 'user' } as const;

const SPLIT_EVENTS = {
  PDP_CLICK: 'pdpClicked',
  PDP_VIEW: 'pdpVisited',
  SIMILAR_DESIGN_CLICK: 'similarDesignClick',
} as const;

type SplitEvent = typeof SPLIT_EVENTS[keyof typeof SPLIT_EVENTS];

const SPLIT_LOGS = {
  SPLIT_ERROR_INITIALIZING: 'split-error-initializing',
  SPLIT_ERROR_TRACK: 'split-error-tracking',
  SPLIT_INIT_EXISTING_COOKIE: 'split-init-existing-cookie',
  SPLIT_INIT_EXISTING_KEY: 'split-init-existing-key',
  SPLIT_INIT_NO_COOKIE: 'split-init-no-cookie',
};

const SPLIT_SALT = 'erER8Cm4m1tJ2iy3xq4K2CjUpjBZ1AFY';

const SPLIT_IO_URL_PARAMS = {
  DISABLED: 'splitDisabled',
  FEATURE: 'feature',
  TREATMENT: 'treatment',
} as const;

export {
  CHANNEL_NAME,
  SPLIT_EVENTS,
  SPLIT_IO_TREATMENT,
  SPLIT_IO_URL_PARAMS,
  SPLIT_LOGS,
  SPLIT_SALT,
  SPLIT_TRAFFIC_TYPE,
  type SplitEvent,
  type SplitIoTreatment,
};
